html {
  height: 100%;
}

body {
  font-family: "Press Start 2P";
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}

#page {
  display: flex;
  height: calc( 100vh - 30px);
}

.side-bar-wrapper {
  width: 97vw;
  min-height: 10vh;
  margin: 1vh 1vw;
}

#content {
  padding: 0 1vw;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  text-decoration: none;
  color: black;
}

a:visited {
  text-decoration: none;
}

li {
  text-decoration: none;
  list-style: none;
}

.App-title {
  text-decoration: none;
  color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(3px + 2vmin);
  color: white;
  margin-bottom: 5vh;
}

.App-link {
  color: #09d3ac;
}

.user-form {
  max-width: 50vw;
}

.form-container {
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
}

.user-form {
  max-width: 25vw;
}

.toggle-form {
  display: flex;
  justify-content: left;
  max-height: 3vh;
}

.body-container {
  /* display: flex; */
  flex-direction: row;
}

button input {
  color: white;
  background-color: Transparent;
  border-style: none;
}

.challenge-input {
  font-size: x-large;
}

#balloony {
  float: right;
}

.chat-bot {
  display: inline-table;
}

.nes-kirby {
  display: none;
}

#user-form input {
  min-width: 70vw;
}

#side-bar {
  padding-left: 2.2vw;
  padding-right: 2.2vw;
}

#new-challenge {
  display: inline-block;
  width: 82vw;
  margin-bottom: 3vh;
}

#instructions {
  display: inline-block;
  width: auto;
  width: 82vw;
  padding: 0 3vw 0 0;
}

#new-challenge input {
  padding-left: 1.2vw;
  padding-right: 1.2vw;
}

#num-players input {
  padding-left: 2vw;
  padding-right: 2vw;
}

#new-chall-button {
  width: 65vw;
}

#main {
  min-height: 50vh;
}

#footer {
  display:flex;
  width: 97vw;
  /* position: absolute; */
  bottom: 0;
  left: 0;
  /* justify-content: center; */
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
}

#super-cool-bois-container {
  float: right;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
}

#shane {
  float: right;
  margin: 1vw;
  margin-bottom: 0;
}

#sukrit {
  float: left;
  margin: 1vw;
  margin-bottom: 0;
}

#github {
  float: left;
}

#octocat {
  margin-top: 1vh;
  margin-left: 1vh;
}

.bio {
  display: none;
}

.desktop-only {
  display: none;
}

#prompt {
  white-space: nowrap;
  overflow-x: hidden;
}

#completed-words {
  display: none;
}



@media only screen and (min-width: 600px) {
  #prompt {
    white-space: normal;
    overflow: auto;
  }

  #completed-words {
    display: contents;
  }
}

@media only screen and (min-width: 1024px) {
  #content {
    padding: 1vh 1vw;
  }
  .desktop-only {
    display: inline-block;
  }
  .side-bar-wrapper {
    width: 20vw;
    float: left;
  }
  #side-bar {
    min-height: 50vh;
  }
  #footer {
    display:flex;
    width: 99vw;
    /* position: absolute; */
    bottom: 0;
    left: 0;
    /* justify-content: center; */
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .App-header {
    background-color: #282c34;
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + 2vmin);
    color: white;
    margin-bottom: 5vh;
  }
  #new-challenge {
    width: 35vw;
    margin: 2vw;
    display: inline-block;
    justify-content: center;
  }

  #instructions {
    margin: 2vw;
    display: inline-block;
    padding: 1.1vw;
  }

  #instructions-list li {
    margin-top: 3vh;
  }

  #new-chall-button {
    width: 30vw;
  }
  .body-container {
    display: flex;
    flex-direction: row;
    min-height: 50vh;
  }
  #user-form input {
    min-width: 20vw;
  }
  .challenge-input {
    width: inherit;
    font-size: x-large;
  }
  .sidebar-btn {
    width: 10vw;
  }
  .nes-kirby {
    display: inline-block;
    margin-bottom: 1vh;
    margin-top: 2vh;
  }
  .bio {
    margin-bottom: 1vh;
    display: block;
  }
  #side-bar-ul {
    padding-left: 0;
    display: grid;
    grid-template-columns: 15vw;
    grid-template-rows: 135px 50px 0px 100px;
    justify-items: center;
  }
  #octocat {
    margin-top: 1vh;
    margin-left: 1vh;
  }
}


/* #info-container div {
  flex: 1;

} */
